import getConfig from 'next/config';
import { SetStateAction } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useIntl } from 'react-intl';
import { getIntlErrorMessage } from './use-form-validation';

interface Params {
  action?: string;
  setIsSubmitting: (value: SetStateAction<boolean>) => void;
  setSubmitError: (value: SetStateAction<string[] | undefined>) => void;
}
export const useRecaptcha = ({
  action,
  setIsSubmitting,
  setSubmitError,
}: Params) => {
  const { publicRuntimeConfig } = getConfig();
  const intl = useIntl();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const checkRecaptcha = async () => {
    if (publicRuntimeConfig.NEXT_PUBLIC_REQUIRE_CAPTCHA !== 'true') {
      return 'no-token-needed';
    }
    if (!executeRecaptcha) {
      setIsSubmitting(false);
      setSubmitError([getIntlErrorMessage('recaptchaNotLoaded', intl)]);
      return;
    }

    let token;
    try {
      token = await executeRecaptcha(action);
    } catch (err) {}
    if (!token) {
      setIsSubmitting(false);
      setSubmitError([getIntlErrorMessage('recaptchaFailed', intl)]);
      return;
    }
    return token;
  };
  return { checkRecaptcha };
};
