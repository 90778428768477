import React, { Dispatch, SetStateAction } from 'react';
import { IntlShape } from 'react-intl';

// inspiration from https://github.com/nextauthjs/next-auth/blob/main/packages/next-auth/src/react/index.tsx#L69
export const useOnline = () => {
  const [isOnline, setIsOnline] = React.useState(
    typeof navigator !== 'undefined' ? navigator.onLine : false
  );

  const setOnline = () => setIsOnline(true);
  const setOffline = () => setIsOnline(false);

  React.useEffect(() => {
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  return isOnline;
};

interface OfflineErrrorsParams {
  intl: IntlShape;
  isOnline: boolean;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
  setSubmitError: Dispatch<SetStateAction<string[] | undefined>>;
}
/**
 * Returns false if errors were set
 */
export const setOfflineErrors = (params: OfflineErrrorsParams) => {
  if (!params.isOnline) {
    params.setIsSubmitting(false);
    params.setSubmitError([
      params.intl.formatMessage({
        id: 'asfd',
        defaultMessage: 'You are offline. Please reconnect and try again.',
        description:
          'Error message shown to users if they try to submit a form when offline.',
      }),
    ]);
    return true;
  }
  return false;
};
