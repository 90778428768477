import React, { useEffect, useState } from 'react';
import pick from 'lodash.pick';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useIntl } from 'react-intl';
import { getCsrfToken } from 'next-auth/react';
import {
  FormErrors,
  getIntlErrorMessage,
  handleFormChange,
  setResponseErrorMessages,
  validateMinMaxLength,
  validateRequiredFields,
} from './use-form-validation';
import { setOfflineErrors, useOnline } from './use-online';
import { useRecaptcha } from './use-recaptcha';
import { fetchPost } from '@fetch/helpers';
import { CreatePaymentMethodRequest } from '@models/http/create-payment-method-request';
import { PaymentMethod, PaymentMethodTemp } from '@models/payment-method';
import { PaymentMethodType } from '@models/payment-method-type';
import { OrgFinancialCountry } from '@models/organization';
dayjs.extend(customParseFormat);

export interface PaymentMethodFormData extends CreatePaymentMethodRequest {
  saved_for_later?: boolean;
}

interface ValidationParams {
  values: PaymentMethodFormData;
}

export const validatePaymentMethodForm = ({ values }: ValidationParams) => {
  const requiredFields =
    values.kind === 'ElectronicCheck'
      ? requiredFieldsCheck
      : requiredFieldsCard;

  let errors = validateRequiredFields(requiredFields, values);
  if (values.kind === 'ElectronicCheck') {
    if (values.routing_number && values.routing_number.length !== 9) {
      errors.routing_number = {
        type: 'custom',
        message: 'Routing number is the wrong length (should be 9 characters).',
      };
    }
    errors.account_number =
      validateMinMaxLength({
        value: values.account_number,
        min: 4,
        max: 17,
      }) || errors.account_number;
  } else if (values.kind === 'CreditCard') {
    errors.card_number =
      validateMinMaxLength({
        value: values.card_number,
        min: 13,
        max: 16,
      }) || errors.card_number;
    errors.security_code =
      validateMinMaxLength({
        value: values.security_code,
        min: 3,
        max: 4,
      }) || errors.security_code;
    if (
      values.expiration_month &&
      (+values.expiration_month < 1 || +values.expiration_month > 12)
    ) {
      errors.expiration_month = {
        type: 'custom',
        message: 'Expiration month must be between 1 and 12.',
      };
    }
    const expirationDate = dayjs(
      `${values.expiration_month}/${values.expiration_year}`,
      'MM/YYYY'
    );
    if (expirationDate.format() !== 'Invalid Date') {
      if (expirationDate.isBefore(dayjs().subtract(1, 'month'))) {
        errors.expiration_year = {
          type: 'custom',
          message: 'Expiration date must be in the future.',
        };
      }
    }

    errors.postal_code =
      validateMinMaxLength({
        value: values.postal_code,
        max: 16,
      }) || errors.postal_code;
  }
  const hasErrors = Object.values(errors).some((e) => e && e.type);
  return { errors, hasErrors };
};

export const initializePaymentMethodFormData = (
  defaultCountryOfUse: OrgFinancialCountry | undefined,
  kind: PaymentMethodType,
  prefill?: any
) => {
  const formData: PaymentMethodFormData = {
    kind: kind,
    name_on_card: prefill?.name_on_card || '',
    account_type: prefill?.account_type || '',
    routing_number: prefill?.routing_number || '',
    account_number: prefill?.account_number || '',
    card_number: prefill?.card_number || '',
    security_code: prefill?.security_code || '',
    expiration_year: prefill?.expiration_year || '',
    expiration_month: prefill?.expiration_month || '',
    country_of_use: prefill?.country_of_use || defaultCountryOfUse,
    postal_code: prefill?.postal_code || '',
    nickname: prefill?.nickname || '',
    saved_for_later: prefill?.saved_for_later || false,
  };
  return formData;
};

interface PaymentMethodFormParams {
  isEdit: boolean;
  onSuccess: (newPaymentMethod: PaymentMethod | PaymentMethodTemp) => void;
  kind: PaymentMethodType;
  defaultCountryOfUse?: OrgFinancialCountry;
  isTempSave?: boolean;
  prefill?: PaymentMethodTemp;
}

const requiredFieldsCheck: (keyof PaymentMethodFormData)[] = [
  'kind',
  'name_on_card',
  'account_type',
  'routing_number',
  'account_number',
  'country_of_use',
];
const requiredFieldsCard: (keyof PaymentMethodFormData)[] = [
  'kind',
  'name_on_card',
  'card_number',
  'security_code',
  'expiration_year',
  'expiration_month',
  'postal_code',
  'country_of_use',
];

export const getPaymentMethodFieldsForSave = (
  values: PaymentMethodFormData
) => {
  const generalFields: (keyof PaymentMethodFormData)[] = ['nickname'];
  const reqFields =
    values.kind === 'ElectronicCheck'
      ? requiredFieldsCheck
      : requiredFieldsCard;
  return pick(values, [...generalFields, ...reqFields]);
};

export const saveNewPaymentMethod = async (
  values: PaymentMethodFormData,
  saved_for_later: boolean,
  recaptcha: string
) => {
  const csrfToken = await getCsrfToken();
  return fetchPost({
    url: `/api/v3/payment_methods`,
    data: {
      ...getPaymentMethodFieldsForSave(values),
      saved_for_later,
      recaptcha,
      csrfToken,
    },
  });
};

const usePaymentMethodForm = ({
  isEdit,
  onSuccess,
  kind,
  defaultCountryOfUse = null,
  isTempSave = true,
  prefill,
}: PaymentMethodFormParams) => {
  const intl = useIntl();
  const isOnline = useOnline();
  const [values, setValues] = useState(
    initializePaymentMethodFormData(defaultCountryOfUse, kind, prefill)
  );
  const [errors, setErrors] = useState<FormErrors<PaymentMethodFormData>>();
  const [hasErrors, setFormHasErrors] = useState(false);
  const [submitError, setSubmitError] = useState<string[]>();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { checkRecaptcha } = useRecaptcha({
    action: 'newPaymentMethodForm',
    setIsSubmitting,
    setSubmitError,
  });

  const reset = () => {
    setValues(initializePaymentMethodFormData(defaultCountryOfUse, kind));
    setErrors(undefined);
    setFormHasErrors(false);
    setSubmitError(undefined);
    setHasSubmitted(false);
    setIsSubmitting(false);
  };

  useEffect(() => {
    const _errors = validatePaymentMethodForm({ values });
    setErrors(_errors.errors);
    setFormHasErrors(_errors.hasErrors);
  }, [values]);

  useEffect(() => {
    if (!defaultCountryOfUse) {
      return;
    }
    setValues((v) => ({
      ...v,
      country_of_use: v.country_of_use || defaultCountryOfUse,
    }));
  }, [defaultCountryOfUse]);

  const handleChange = handleFormChange({
    values,
    setValues,
  });

  const updateValues = (newValues: Partial<PaymentMethodFormData>) => {
    setValues({
      ...values,
      ...newValues,
    });
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    /* istanbul ignore next */
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    setHasSubmitted(true);
    if (setOfflineErrors({ intl, isOnline, setIsSubmitting, setSubmitError })) {
      return;
    }

    const token = await checkRecaptcha();
    if (!token) {
      return;
    }

    setSubmitError(undefined);
    const _errors = validatePaymentMethodForm({ values });
    setErrors(_errors.errors);
    setFormHasErrors(_errors.hasErrors);
    if (_errors.hasErrors) {
      setIsSubmitting(false);
      setSubmitError([getIntlErrorMessage('formValidationErrors', intl)]);
      return;
    }

    if (isTempSave) {
      const generalFields: (keyof PaymentMethodFormData)[] = [
        'nickname',
        'saved_for_later',
      ];
      const reqFields =
        values.kind === 'ElectronicCheck'
          ? requiredFieldsCheck
          : requiredFieldsCard;
      const newTempMethod: PaymentMethodTemp = {
        ...pick(values, [...generalFields, ...reqFields]),
        id: values.kind === 'ElectronicCheck' ? 'TempBank' : 'TempCard',
      };
      onSuccess(newTempMethod);
    } else {
      try {
        const response = await saveNewPaymentMethod(values, true, token);
        if (response.ok) {
          const data: PaymentMethod = await response.json();
          onSuccess && onSuccess(data);
          reset();
        } else {
          setResponseErrorMessages({
            response,
            setErrors,
            setSubmitError,
            intl,
          });
        }
      } catch (err) {
        setSubmitError([getIntlErrorMessage('processingError', intl)]);
      }
    }
    setIsSubmitting(false);
  };

  return {
    handleChange,
    values,
    handleSubmit,
    errors,
    hasErrors,
    hasSubmitted,
    isSubmitting,
    submitError,
    updateValues,
  };
};

export default usePaymentMethodForm;
